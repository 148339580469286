/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function listCTA(list) {

    if(!list) {
      return null;
    }
    let names = [];        
        list.forEach(element => names.push(" "+`<a href="${Formatter.generateCTAFieldTypeLink(element)}">${element.label}</a>`));

    return names;
  }

export function convertMs(msecs) {
  let minutes = Number
  minutes = msecs / 60000
  minutes = Math.round(minutes*10)/10;
  return String(minutes) 
}  